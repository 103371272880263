import styled from 'styled-components';
import { fontSize } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';

export const Container = styled.div.attrs({
  $fs: 's',
})`
  text-align: left;
  ${fontSize};
  max-height: calc(100vh - var(--eko-header-height, 0px) - 250px);
  overflow: auto;
`;

export const Title = styled.div`
  width: 100%;
  text-align: left;
`;

export const Image = styled.img`
  width: 100%;
  margin-bottom: ${gaps.s}px;
`;

export const ContentTitle = styled.div`
  color: var(--eko-primary-color);
  font-weight: bold;
  margin-bottom: ${gaps.s}px;
`;

export const MainTextsContainer = styled.ul`
  padding-left: 1.5em;
`;
