import React from 'react';

import { useAllowedPortfolioTypes } from '~/shared/hooks/useAllowedPortfolioTypes';
import { PORTFOLIO_TYPE } from '~/entities/protfolio';

import * as S from './WelcomePopup.styled';
import mobile from './mobile.image.png';

const Content = () => {
  const allowedPortfolioTypes = useAllowedPortfolioTypes();

  const isPremadeAllowed = allowedPortfolioTypes.includes(PORTFOLIO_TYPE.PRE_MADE);

  return (
    <S.Container>
      <S.Image src={mobile} alt="new product preview" />
      <S.ContentTitle>Whats new:</S.ContentTitle>
      <S.MainTextsContainer>
        <li>Introducing 100% self-directed portfolios {isPremadeAllowed ? 'and thematic pre-made portfolios' : ''}</li>
        <li>The ability to have multiple portfolios simultaneously</li>
        <li>Updated designs and new functionalities</li>
      </S.MainTextsContainer>
    </S.Container>
  );
};

export const WELCOME_POPUP_CONTENT = {
  title: <S.Title>Explore our new product!</S.Title>,
  nextButtonContent: 'Next',
  content: <Content />,
  width: 550,
};
