import React, { useMemo } from 'react';

import { checkDeviceSize, useAppMediaContext } from '~/contexts/AppMedia';
import { getOnboardingStepCNFactory, OnboardingStep } from '~/widgets/PageOnboarding';

export const getStepCN = getOnboardingStepCNFactory('portfolioControls');

type OnboardingStepsResult = OnboardingStep[];

const EMPTY_RESULTS: OnboardingStepsResult = [];

export const useGetOnboardingStepsHook = (enabled: boolean) => {
  const { deviceSize } = useAppMediaContext();
  const isMobile = !checkDeviceSize.moreOrEqual.md(deviceSize);

  return useMemo((): OnboardingStepsResult => {
    if (!enabled) {
      return EMPTY_RESULTS;
    }

    const stepsByPortfolioType: OnboardingStep[] = [
      {
        id: 'selector',
        className: getStepCN('selector'),
        position: 'bottom-left-aligned' as const,
        disableScrollWidth: 350,
        additionalLeftOffset: isMobile ? 16 : 0,
        content: <>You can create new portfolios, and switch between existing portfolios here.</>,
      },
    ];

    return stepsByPortfolioType;
  }, [enabled, isMobile]);
};
